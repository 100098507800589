* {
  scrollbar-width: thin;
  scrollbar-color: #64748b80 #64748b29;
}

::-webkit-scrollbar {
  background-color: #0000;
  width: .375rem !important;
}

::-webkit-scrollbar-track {
  background-color: #64748b29 !important;
  border-radius: .25rem !important;
}

::-webkit-scrollbar-thumb {
  background-color: #64748b80 !important;
  border-radius: .25rem !important;
}

input[type="range"]::-webkit-slider-thumb {
  background: #d6a600 !important;
}

input[type="range"]::-moz-range-thumb {
  background: #d6a600 !important;
}

input[type="range"]::-ms-thumb {
  background: #d6a600 !important;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #1c64f2;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #1c64f2;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked, .dark [type="checkbox"]:checked, .dark [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto inherit;
}

input[type="file"]::file-selector-button {
  color: #fff;
  cursor: pointer;
  background: #1f2937;
  border: 0;
  margin-inline: -1rem 1rem;
  padding: .625rem 1rem .625rem 2rem;
  font-size: .875rem;
  font-weight: 500;
}

input[type="file"]::file-selector-button:hover {
  background: #374151;
}

.dark input[type="file"]::file-selector-button {
  color: #fff;
  background: #4b5563;
}

.dark input[type="file"]::file-selector-button:hover {
  background: #6b7280;
}

input[type="range"]::-webkit-slider-thumb {
  height: 1.25rem;
  width: 1.25rem;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: #9ca3af;
}

.dark input[type="range"]:disabled::-webkit-slider-thumb {
  background: #6b7280;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1px;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
  outline: 2px solid #0000;
}

input[type="range"]::-moz-range-thumb {
  height: 1.25rem;
  width: 1.25rem;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: #9ca3af;
}

.dark input[type="range"]:disabled::-moz-range-thumb {
  background: #6b7280;
}

input[type="range"]::-moz-range-progress {
  background: #3f83f8;
}

input[type="range"]::-ms-fill-lower {
  background: #3f83f8;
}

input[type="range"].range-lg::-webkit-slider-thumb {
  height: 1.5rem;
  width: 1.5rem;
}

input[type="range"].range-lg::-moz-range-thumb {
  height: 1.5rem;
  width: 1.5rem;
}

[data-popper-arrow], [data-popper-arrow]:before {
  width: 8px;
  height: 8px;
  background: inherit;
  position: absolute;
}

[data-popper-arrow] {
  visibility: hidden;
}

[data-popper-arrow]:before {
  content: "";
  visibility: visible;
  transform: rotate(45deg);
}

[data-popper-arrow]:after {
  content: "";
  visibility: visible;
  width: 9px;
  height: 9px;
  background: inherit;
  position: absolute;
  transform: rotate(45deg);
}

[role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: #e5e7eb;
}

.dark [role="tooltip"] > [data-popper-arrow]:before {
  border-style: solid;
  border-color: #4b5563;
}

[role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #e5e7eb;
}

.dark [role="tooltip"] > [data-popper-arrow]:after {
  border-style: solid;
  border-color: #4b5563;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow]:after {
  border-bottom-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-left-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:before, [data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow]:after {
  border-top-width: 1px;
  border-right-width: 1px;
}

[data-popover][role="tooltip"][data-popper-placement^="top"] > [data-popper-arrow] {
  bottom: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="bottom"] > [data-popper-arrow] {
  top: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="left"] > [data-popper-arrow] {
  right: -5px;
}

[data-popover][role="tooltip"][data-popper-placement^="right"] > [data-popper-arrow] {
  left: -5px;
}

html {
  font-family: InterNormal, system-ui, sans-serif;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3f83f880;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.left-\[30px\] {
  left: 30px;
}

.bottom-0 {
  bottom: 0;
}

.z-50 {
  z-index: 50;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[2\] {
  z-index: 2;
}

.z-0 {
  z-index: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-\[100px\] {
  margin-top: 100px;
}

.mt-1 {
  margin-top: .25rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-screen {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[40\] {
  height: 40px;
}

.h-\[14\] {
  height: 14px;
}

.h-\[4px\] {
  height: 4px;
}

.max-h-screen {
  max-height: 100vh;
}

.max-h-\[90\%\] {
  max-height: 90%;
}

.max-h-\[500\] {
  max-height: 500px;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-full {
  width: 100%;
}

.w-10 {
  width: 2.5rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-auto {
  width: auto;
}

.w-1 {
  width: .25rem;
}

.w-\[14\] {
  width: 14px;
}

.w-\[100\%\] {
  width: 100%;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-none {
  max-width: none;
}

.max-w-full {
  max-width: 100%;
}

.max-w-\[590px\] {
  max-width: 590px;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.flex-1 {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.self-end {
  align-self: flex-end;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-\[25px\] {
  border-radius: 25px;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-\[2px\] {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-dark-blue {
  --tw-border-opacity: 1;
  border-color: rgb(0 39 51 / var(--tw-border-opacity));
}

.border-dark-yellow {
  --tw-border-opacity: 1;
  border-color: rgb(214 166 0 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(240 82 82 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-dark-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(0 39 51 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-\[rgba\(0\,39\,51\,0\.7\)\] {
  background-color: #002733b3;
}

.bg-\[\#fafbfb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 251 / var(--tw-bg-opacity));
}

.bg-dark-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(214 166 0 / var(--tw-bg-opacity));
}

.bg-light-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 238 193 / var(--tw-bg-opacity));
}

.bg-\[\#002733\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 39 51 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-main {
  background-image: url("bg-full.80630e6d.jpg");
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-homeCurve {
  background-image: url("line.467440b7.svg");
}

.bg-homeCard {
  background-image: url("card.d8cb038f.png");
}

.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-to: #fff0;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-light-yellow {
  --tw-gradient-to: #ffeec1;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-\[center_top_-100px\] {
  background-position: 50% -100px;
}

.bg-\[left_top\] {
  background-position: 0 0;
}

.bg-left {
  background-position: 0;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-white {
  fill: #fff;
}

.fill-dark-blue {
  fill: #002733;
}

.p-3 {
  padding: .75rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-2 {
  padding: .5rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-4 {
  padding: 1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.pt-\[80px\] {
  padding-top: 80px;
}

.pt-\[20vh\] {
  padding-top: 20vh;
}

.pt-3 {
  padding-top: .75rem;
}

.pl-\[25\] {
  padding-left: 25px;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pl-3 {
  padding-left: .75rem;
}

.text-center {
  text-align: center;
}

.font-interbold {
  font-family: InterBold, sans-serif;
}

.font-interlight {
  font-family: InterLight, sans-serif;
}

.font-pprightgothic {
  font-family: PPRightGothic, sans-serif;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[16px\] {
  font-size: 16px;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-\[22px\] {
  line-height: 22px;
}

.text-dark-blue {
  --tw-text-opacity: 1;
  color: rgb(0 39 51 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-dark-yellow {
  --tw-text-opacity: 1;
  color: rgb(214 166 0 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(240 82 82 / var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(200 30 30 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-light-yellow {
  --tw-text-opacity: 1;
  color: rgb(255 238 193 / var(--tw-text-opacity));
}

.text-medium-blue {
  --tw-text-opacity: 1;
  color: rgb(133 174 255 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.line-through {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.decoration-1 {
  text-decoration-thickness: 1px;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.opacity-10 {
  opacity: .1;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px #00000008) drop-shadow(0 8px 5px #00000014);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[max-height\] {
  transition-property: max-height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: PPRightGothic;
  src: url("PPRightGothic-Medium.92628248.otf");
}

@font-face {
  font-family: InterLight;
  src: url("Inter-Light.490626be.ttf");
}

@font-face {
  font-family: InterNormal;
  src: url("Inter-Regular.7cfebe77.ttf");
}

@font-face {
  font-family: InterMedium;
  src: url("Inter-Medium.775d466f.ttf");
}

@font-face {
  font-family: InterBold;
  src: url("Inter-Bold.88a04ef3.ttf");
}

.placeholder\:text-\[\#FFFFFF80\]::placeholder {
  color: #ffffff80;
}

.read-only\:bg-gray-100:read-only {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(26 86 219 / var(--tw-ring-opacity));
}

.disabled\:border-gray-400:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.disabled\:bg-gray-400:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm\:max-w-\[500px\] {
    max-width: 500px;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:bg-homeInherit {
    background-image: url("inherit.bfd0dcc4.png");
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-\[34px\] {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .md\:max-w-\[300px\] {
    max-width: 300px;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 1024px) {
  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mt-\[200px\] {
    margin-top: 200px;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:max-h-fit {
    max-height: -moz-fit-content;
    max-height: fit-content;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .lg\:max-w-fit {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:overflow-visible {
    overflow: visible;
  }

  .lg\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:border-\[2px\] {
    border-width: 2px;
  }

  .lg\:bg-\[left_top_-50px\] {
    background-position: 0 -50px;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-7 {
    padding: 1.75rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:text-\[12px\] {
    font-size: 12px;
  }

  .lg\:text-\[40px\] {
    font-size: 40px;
  }

  .lg\:text-\[24px\] {
    font-size: 24px;
  }

  .lg\:text-\[19px\] {
    font-size: 19px;
  }

  .lg\:text-\[56px\] {
    font-size: 56px;
  }

  .lg\:text-\[26px\] {
    font-size: 26px;
  }

  .lg\:opacity-40 {
    opacity: .4;
  }
}

@media (min-width: 1280px) {
  .xl\:mt-\[260px\] {
    margin-top: 260px;
  }

  .xl\:bg-\[left_top_-100px\] {
    background-position: 0 -100px;
  }

  .xl\:pt-\[140px\] {
    padding-top: 140px;
  }

  .xl\:text-\[60px\] {
    font-size: 60px;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:mt-\[320px\] {
    margin-top: 320px;
  }

  .\32 xl\:pt-\[220px\] {
    padding-top: 220px;
  }
}

.checkout-form form {
  width: 30vw;
  min-width: 500px;
  background: none;
  align-self: center;
  padding: 0;
}

.checkout-form #payment-message {
  color: #df1b41;
  text-align: center;
  padding-top: 12px;
  font-size: 16px;
  line-height: 20px;
}

.checkout-form #payment-element {
  margin-bottom: 24px;
}

.checkout-form button {
  color: #002733;
  cursor: pointer;
  width: 100%;
  background: #fff;
  border: 0;
  border-radius: 25px;
  padding: 12px 16px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  transition: all .2s;
  display: block;
  box-shadow: 0 4px 5.5px #00000012;
}

.checkout-form button:hover {
  filter: contrast(115%);
}

.checkout-form button:disabled {
  opacity: .5;
  cursor: default;
}

.checkout-form .spinner, .checkout-form .spinner:before, .checkout-form .spinner:after {
  border-radius: 50%;
}

.checkout-form .spinner {
  color: #fff;
  text-indent: -99999px;
  width: 20px;
  height: 20px;
  margin: 0 auto;
  font-size: 22px;
  position: relative;
  transform: translateZ(0);
  box-shadow: inset 0 0 0 2px;
}

.checkout-form .spinner:before, .checkout-form .spinner:after {
  content: "";
  position: absolute;
}

.checkout-form .spinner:before {
  width: 10.4px;
  height: 20.4px;
  transform-origin: 10.4px 10.2px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  animation: 2s 1.5s infinite loading;
  top: -.2px;
  left: -.2px;
}

.checkout-form .spinner:after {
  width: 10.4px;
  height: 10.2px;
  transform-origin: 0 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  animation: 2s infinite loading;
  top: -.1px;
  left: 10.2px;
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .checkout-form form {
    width: 100%;
    min-width: initial;
  }
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
  content: "";
  height: 9px;
  width: 9px;
  border: 3px solid #ccc;
  border-width: 3px 3px 0 0;
  display: block;
  position: absolute;
  top: 6px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  width: 0;
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border: 8px solid #0000;
  position: absolute;
  left: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  border: 0;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  width: 100%;
  background-color: #fff;
  border: 2px solid #002733;
  border-radius: 20px;
  font-family: Helvetica Neue, helvetica, arial, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 20px;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 50%;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 5px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  margin-bottom: 8px;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 8px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover :before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  width: 0;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next:before {
  left: -7px;
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous:before {
  right: -7px;
  transform: rotate(225deg);
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  width: 85px;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: 50%;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: 50%;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  text-align: center;
  border-bottom-right-radius: 50%;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + .85rem);
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  white-space: nowrap;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #d6a600;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #d6a600;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__week-number {
  color: #ccc;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: 50%;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  width: 30px;
  text-align: center;
  margin: 5px;
  font-size: 14px;
  line-height: 30px;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #d6a600;
  border-radius: 50%;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: 50%;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: 50%;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff;
  background-color: #d6a600;
  border-radius: 50%;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #bd9400;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #d6a600;
  border-radius: 50%;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #d6a600;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: #216ba580;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: #0000;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #d6a600;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #0000;
  border-radius: 50%;
  position: relative;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: 50%;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: #0000;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #d6a600;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147483647;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__month-select, .react-datepicker__year-select {
  border-radius: 10px;
  font-size: 14px;
  padding: 4px 35px 4px 10px !important;
}

.react-datepicker__month-select:focus, .react-datepicker__year-select:focus {
  border-width: 1px;
  box-shadow: none !important;
  border-color: #d6a600 !important;
}

/*# sourceMappingURL=index-dev.dbea04f7.css.map */
